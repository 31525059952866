import {
  useEffect,
  useState,
  Fragment,
  useRef,
  useCallback,
  useMemo,
  Suspense,
} from 'react';
import { Link, LoaderFunctionArgs, useParams } from 'react-router-dom';
import {
  AlchemyProvider,
  Contract,
  AbiCoder,
  Interface,
  Wallet,
  parseEther,
  EventLog,
} from 'ethers';
import { cosmoClient, BodyItem, PollDetail, Poll } from '../cosmo';
import { getWalletMnemonic } from '../ramper';
import { useCurrentAccount } from '../credentials';
import {
  useDisclosure,
  Tabs,
  Tab,
  RadioGroup,
  Radio,
  Input,
  Button,
  ScrollShadow,
  Spinner,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Avatar,
  AvatarGroup,
  Tooltip as NextUITooltip,
  Switch,
} from '@nextui-org/react';
import { motion } from 'framer-motion';
import { BarChart, Bar, Tooltip, ResponsiveContainer } from 'recharts';
import { ComoTripleSIcon, ComoArtmsIcon, DownloadIcon } from '../icons';
import html2canvas from 'html2canvas';
import { QRCodeCanvas } from 'qrcode.react';
import {
  useSuspenseQuery,
  useSuspenseQueries,
  useQuery,
} from '@tanstack/react-query';
import { queryClient } from '../query';
import {
  batchNicknamesQuery,
  gravityQuery,
  pollDetailQuery,
  searchUsersQuery,
} from '../queries/cosmo';
import {
  blockTimestampQuery,
  batchBlockTimestampsQuery,
} from '../queries/polygon';

let provider = new AlchemyProvider('matic', 'jKHL8FBDC9OR14KUb_n-J0_5KoF9hjDo');
let contractAddresses = {
  artms: '0x8466e6E218F0fe438Ac8f403f684451D20E59Ee3',
  tripleS: '0xc3E5ad11aE2F00c740E74B81f134426A3331D950',
};
let comoContractAddresses = {
  artms: '0x8254D8D2903B20187cBC4Dd833d49cECc219F32E',
  tripleS: '0x58AeABfE2D9780c1bFcB713Bf5598261b15dB6e5',
};
let contractAbi = [
  'event Finalized(uint256 indexed pollId, uint256 burned)',
  'event Initialized(uint8 version)',
  'event PollCreated(uint256 pollId)',
  'event Revealed(uint256 indexed pollId, uint256 revealedVotes, uint256 remainingVotes)',
  'event RoleAdminChanged(bytes32 indexed role, bytes32 indexed previousAdminRole, bytes32 indexed newAdminRole)',
  'event RoleGranted(bytes32 indexed role, address indexed account, address indexed sender)',
  'event RoleRevoked(bytes32 indexed role, address indexed account, address indexed sender)',
  'event Voted(uint256 indexed pollId, uint256 voteIndex, address voter, uint256 comoAmount, bytes32 hash)',
  'function DEFAULT_ADMIN_ROLE() view returns (bytes32)',
  'function OPERATOR_ROLE() view returns (bytes32)',
  'function candidates(uint256 pollId) view returns (string[])',
  'function comoContract() view returns (address)',
  'function createPoll(string title_, string[] candidates_, uint256 startAt_, uint256 due_, uint256 minimumCOMO_)',
  'function finalize(uint256 pollId)',
  'function getRoleAdmin(bytes32 role) view returns (bytes32)',
  'function grantRole(bytes32 role, address account)',
  'function hasRole(bytes32 role, address account) view returns (bool)',
  'function hashes(uint256, bytes32) view returns (bool)',
  'function initialize(address voteSignerAddress_, address comoAddress_)',
  'function isInProgress(uint256 pollId) view returns (bool)',
  'function isRevealedVote(uint256, uint256) view returns (bool)',
  'function pollResult(uint256 pollId) view returns (tuple(string candidate, uint256 votes)[])',
  'function polls(uint256) view returns (string title, uint256 startAt, uint256 due, uint256 minimumCOMO, uint256 totalVotedCOMO, uint256 revealedVotes, bool finalized)',
  'function remainingVotes(uint256 pollId) view returns (uint256)',
  'function renounceRole(bytes32 role, address account)',
  'function reset(uint256 pollId, uint256 missingOffset, tuple(uint256 comoAmount, bytes32 hash)[] missingCommitData)',
  'function reveal(uint256 pollId, tuple(uint256 votedCandidateId, bytes32 salt)[] data, uint256 offset)',
  'function revokeRole(bytes32 role, address account)',
  'function setVoteSignerAddress(address addr)',
  'function supportsInterface(bytes4 interfaceId) view returns (bool)',
  'function tokensReceived(address operator, address from, address to, uint256 amount, bytes userData, bytes operatorData)',
  'function totalVotes(uint256 pollId) view returns (uint256)',
  'function userVoteResult(uint256 pollId, address voter) view returns (tuple(string candidate, uint256 votes)[])',
  'function userVoteResults(uint256, address, uint256) view returns (uint256)',
  'function voteSignerAddress() view returns (address)',
  'function voters(uint256, uint256) view returns (address)',
  'function votes(uint256 pollId) view returns (tuple(uint256 comoAmount, bytes32 hash)[])',
  'function votesPerCandidates(uint256 pollId) view returns (uint256[])',
];
let contracts = {
  artms: new Contract(contractAddresses['artms'], contractAbi, provider),
  tripleS: new Contract(contractAddresses['tripleS'], contractAbi, provider),
};

function VoteCertificate({
  artist,
  voteData: {
    nickname,
    gravityId,
    pollId,
    choiceId,
    comoAmount,
    blockNumber,
    salt,
  },
}: {
  artist: 'tripleS' | 'artms';
  voteData: {
    nickname: string;
    gravityId: number;
    pollId: number;
    choiceId: string;
    comoAmount: number;
    blockNumber: number;
    salt: string;
  };
}) {
  let { data: searchResults } = useSuspenseQuery(searchUsersQuery(nickname));
  let profile = searchResults.results[0];
  let address = profile.address;
  let { data: gravity } = useSuspenseQuery(gravityQuery(artist, gravityId));
  let { data: pollDetail } = useSuspenseQuery(
    pollDetailQuery(artist, gravityId, pollId),
  );
  let choice = pollDetail.choices.find(c => c.id === choiceId)!;
  let gravityTitle = gravity.title;
  let choiceImageUrl = choice.txImageUrl;
  let { data: timestamp } = useSuspenseQuery(blockTimestampQuery(blockNumber));

  let certificateRef = useRef<HTMLDivElement>(null);
  let downloadCertificate = useCallback(() => {
    if (certificateRef.current) {
      html2canvas(certificateRef.current, { useCORS: true }).then(canvas => {
        let link = document.createElement('a');
        link.download = 'cosmo-gravity-certificate.png';
        link.href = canvas.toDataURL();
        link.click();
      });
    }
  }, []);

  let certificateUrl = useMemo(() => {
    let params = new URLSearchParams({
      artist,
      nickname,
      address: address ?? '',
      gravityId: gravityId.toString(),
      pollId: pollId.toString(),
      choiceId,
      comoAmount: comoAmount.toString(),
      blockNumber: blockNumber.toString(),
      salt,
    });
    let url = `https://cosmo.goranmoomin.dev/gravity/certificate?${params.toString()}`;
    if (address) {
      cosmoClient.ntfy(
        'cosmo-certs',
        `New Vote Certificate: ${comoAmount} COMO to ${choiceId}`,
        {
          Click: url,
        },
      );
    }
    return url;
  }, [
    nickname,
    address,
    gravityId,
    pollId,
    choiceId,
    comoAmount,
    blockNumber,
    salt,
  ]);

  return (
    <div className='relative'>
      <div
        ref={certificateRef}
        className='bg-gradient-to-br from-purple-200 to-pink-200 p-6 max-w-md mx-auto rounded-lg shadow-lg'
      >
        <h2 className='text-2xl uppercase font-bold mb-4 text-center text-purple-800'>
          Voting Certificate
        </h2>

        <div className='flex justify-center mb-4'>
          <img
            crossOrigin=''
            src={choiceImageUrl.replace(
              'https://static.cosmo.fans',
              'https://proxy.goranmoomin.dev/cosmo-static',
            )}
            alt={`Choice: ${choiceId}`}
            className='w-24 h-24 rounded-full border-4 border-white shadow-md'
          />
        </div>

        <h3 className='text-xl font-semibold mb-4 text-center text-purple-700'>
          {gravityTitle}
        </h3>

        <div className='text-center mb-4'>
          <div className='font-semibold text-lg text-black'>{choiceId}</div>
          <div className='text-sm text-gray-600'>Choice</div>
        </div>

        <div className='flex justify-center gap-8 mb-4'>
          <div className='text-center'>
            <div className='font-semibold text-black'>{nickname}</div>
            <div className='text-xs text-gray-600'>User Name</div>
          </div>
          <div className='text-center'>
            <div className='font-semibold text-black'>{comoAmount} COMO</div>
            <div className='text-xs text-gray-600'>Amount Spent</div>
          </div>
        </div>

        <div className='text-center mb-4'>
          <div className='font-semibold text-black'>
            {/* Handle timezones */}
            {new Date(timestamp * 1000).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })}
          </div>
          <div className='text-xs text-gray-600'>Poll Completed</div>
        </div>

        <div className='text-center mb-4 text-sm text-gray-700'>
          This Certificate validates that your vote has been successfully cast.
        </div>

        <div className='flex justify-center mb-4'>
          <QRCodeCanvas value={certificateUrl} size={128} />
        </div>

        <div className='text-center text-xs text-gray-500'>
          Proof:
          <br />
          {salt.slice(2, 34)}
          <br />
          {salt.slice(34)}
        </div>
      </div>
      <Button
        isIconOnly
        color='secondary'
        aria-label='Download'
        className='absolute bottom-2 right-2'
        onClick={downloadCertificate}
      >
        <DownloadIcon className='w-4 h-4' />
      </Button>
    </div>
  );
}

function VoteTab({
  artist,
  pollDetail,
}: {
  artist: 'tripleS' | 'artms';
  pollDetail: PollDetail;
}) {
  let currentAccount = useCurrentAccount();
  let ramperWalletSecrets = currentAccount?.ramperWalletSecrets;

  let [choiceId, setChoiceId] = useState<string | null>(null);
  let [comoAmount, setComoAmount] = useState<number>(1);
  let [isVoting, setIsVoting] = useState(false);
  let [voteError, setVoteError] = useState<string | null>(null);
  let [voteData, setVoteData] = useState<{
    nickname: string;
    gravityId: number;
    pollId: number;
    choiceId: string;
    comoAmount: number;
    blockNumber: number;
    salt: string;
  } | null>(null);

  let {
    isOpen: isVoteModalOpen,
    onOpen: onVoteModalOpen,
    onOpenChange: onVoteModalOpenChange,
  } = useDisclosure();
  let {
    isOpen: isConfirmModalOpen,
    onOpen: onConfirmModalOpen,
    onOpenChange: onConfirmModalOpenChange,
  } = useDisclosure();

  async function handleVoteConfirm(pollId: number) {
    onConfirmModalOpenChange();
    setIsVoting(true);
    setVoteError(null);
    try {
      let { version, dek, encryptedKey } = ramperWalletSecrets!;
      let wallet = Wallet.fromPhrase(
        await getWalletMnemonic(version, dek, encryptedKey),
        provider,
      );

      let { callData } = await cosmoClient.fabricateVote(
        artist,
        pollId,
        choiceId!,
        comoAmount,
      );

      let abiCoder = new AbiCoder();
      let encodedVoteData = abiCoder.encode(
        ['uint256', 'bytes32', 'bytes'],
        [callData.pollIdOnChain, callData.hash, callData.signature],
      );

      let erc777Interface = new Interface([
        'function send(address to, uint256 amount, bytes calldata data) external',
      ]);

      let txData = erc777Interface.encodeFunctionData('send', [
        contractAddresses[artist],
        parseEther(comoAmount.toString()),
        encodedVoteData,
      ]);

      console.log(txData);

      let tx = {
        to: comoContractAddresses[artist],
        data: txData,
      };

      let txResponse = await wallet.sendTransaction(tx);
      let receipt = await txResponse.wait();

      setVoteData({
        nickname: currentAccount!.profile.nickname,
        gravityId: pollDetail.gravityId,
        pollId,
        choiceId: choiceId!,
        comoAmount,
        blockNumber: receipt!.blockNumber,
        salt: callData.salt,
      });
      onVoteModalOpen();
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        setVoteError(error.message);
      } else {
        setVoteError('An unknown error occurred while voting.');
      }
    } finally {
      setIsVoting(false);
    }
  }

  function handleVote(_pollId: number) {
    if (!choiceId || comoAmount <= 0 || !ramperWalletSecrets) {
      setVoteError(
        "Please select a choice, enter a valid COMO amount, and ensure you're logged in",
      );
      return;
    }
    onConfirmModalOpen();
  }

  let selectedChoice = choiceId
    ? pollDetail.choices.find(c => c.id === choiceId)
    : null;

  return (
    <div>
      <ScrollShadow className='max-h-[300px]'>
        <RadioGroup
          value={choiceId}
          onValueChange={setChoiceId}
          className='mb-4'
        >
          {pollDetail.choices.map(choice => (
            <Radio
              className='inline-flex m-0 bg-content1 hover:bg-content2 items-center justify-between flex-row-reverse max-w-full rounded-lg gap-4 p-4 border-2 border-transparent data-[selected=true]:border-primary'
              key={choice.id}
              value={choice.id}
            >
              <div className='flex items-center'>
                <img
                  src={choice.txImageUrl}
                  alt={choice.title}
                  className='w-12 h-12 object-cover mr-2'
                />
                <div className='flex flex-col'>
                  <span>{choice.title}</span>
                  <span className='text-xs text-gray-500'>
                    {choice.description}
                  </span>
                </div>
              </div>
            </Radio>
          ))}
        </RadioGroup>
      </ScrollShadow>
      <div className='flex items-center'>
        <Input
          type='number'
          inputMode='numeric'
          pattern='[0-9]*'
          placeholder='1'
          startContent={
            artist === 'tripleS' ? (
              <ComoTripleSIcon className='w-4 h-4' />
            ) : (
              <ComoArtmsIcon className='w-4 h-4' />
            )
          }
          endContent={<span className='text-default-400 text-small'>COMO</span>}
          classNames={{ input: 'text-base placeholder:text-small' }}
          className='mr-2 flex-grow'
          value={comoAmount.toString()}
          onChange={e => setComoAmount(parseInt(e.target.value))}
        />
        <Button
          color='primary'
          onClick={() => handleVote(pollDetail.id)}
          isDisabled={!choiceId}
          isLoading={isVoting}
        >
          Vote
        </Button>
      </div>
      {voteError && <p className='text-red-500 mt-2'>{voteError}</p>}

      <Modal isOpen={isVoteModalOpen} onOpenChange={onVoteModalOpenChange}>
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Vote Certificate
              </ModalHeader>
              <ModalBody>
                {voteData && (
                  <VoteCertificate artist={artist} voteData={voteData} />
                )}
              </ModalBody>
              <ModalFooter>
                <Button color='primary' onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isConfirmModalOpen}
        onOpenChange={onConfirmModalOpenChange}
      >
        <ModalContent>
          {onClose => (
            <>
              <ModalHeader className='flex flex-col gap-1'>
                Confirm Vote
              </ModalHeader>
              <ModalBody>
                {selectedChoice && (
                  <div className='space-y-4'>
                    <div className='flex items-center gap-4'>
                      <img
                        src={selectedChoice.txImageUrl}
                        alt={selectedChoice.title}
                        className='w-16 h-16 object-cover rounded-lg'
                      />
                      <div>
                        <h3 className='font-semibold'>
                          {selectedChoice.title}
                        </h3>
                        <p className='text-sm text-gray-500'>
                          {selectedChoice.description}
                        </p>
                      </div>
                    </div>
                    <div className='flex items-center gap-2 text-lg'>
                      <span>Amount:</span>
                      <span className='font-semibold'>{comoAmount} COMO</span>
                    </div>
                    <p className='text-sm text-gray-500'>
                      Please confirm your vote. This action cannot be undone.
                    </p>
                  </div>
                )}
              </ModalBody>
              <ModalFooter>
                <Button color='danger' variant='light' onPress={onClose}>
                  Cancel
                </Button>
                <Button
                  color='primary'
                  onPress={() => handleVoteConfirm(pollDetail.id)}
                >
                  Vote for {selectedChoice!.title}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}

function LeaderboardTab({
  voteLogs,
  votedCandidateIds,
  pollDetail,
  isLoading,
}: {
  voteLogs: VoteLog[];
  votedCandidateIds: bigint[];
  pollDetail: PollDetail;
  isLoading: boolean;
}) {
  return (
    <div className='flex flex-col gap-4'>
      <Suspense
        fallback={
          <div className='w-full h-64 flex items-center justify-center'>
            <Spinner />
          </div>
        }
      >
        <TopSpendersTable
          voteLogs={voteLogs}
          votedCandidateIds={votedCandidateIds}
          pollDetail={pollDetail}
          isLoading={isLoading}
        />
      </Suspense>
    </div>
  );
}

type VoteLog = {
  pollId: bigint;
  voteIndex: bigint;
  voter: string;
  comoAmount: bigint;
  hash: string;
  blockNumber: number;
};

function TopSpendersTable({
  voteLogs,
  votedCandidateIds,
  pollDetail,
  isLoading,
}: {
  voteLogs: VoteLog[];
  votedCandidateIds: bigint[];
  pollDetail: PollDetail;
  isLoading: boolean;
}) {
  let [showTimestamps, setShowTimestamps] = useState(false);

  let topSpenders = useMemo(() => {
    let spenderMap = new Map<
      string,
      {
        totalAmount: bigint;
        votes: { candidateId: bigint | null; amount: bigint; index: number }[];
      }
    >();

    for (let i = 0; i < voteLogs.length; i++) {
      let log = voteLogs[i];
      let candidateId =
        i < votedCandidateIds.length ? votedCandidateIds[i] : null;
      let currentData = spenderMap.get(log.voter) || {
        totalAmount: 0n,
        votes: [],
      };

      spenderMap.set(log.voter, {
        totalAmount: currentData.totalAmount + log.comoAmount,
        votes: [
          ...currentData.votes,
          { candidateId, amount: log.comoAmount, index: i },
        ],
      });
    }

    return Array.from(spenderMap.entries())
      .sort((a, b) => (b[1].totalAmount > a[1].totalAmount ? 1 : -1))
      .slice(0, 50)
      .map(([address, data], index) => ({
        rank: index + 1,
        address,
        totalAmount: Number(data.totalAmount / 1000000000000000000n),
        votes: data.votes.map(vote => ({
          candidateId: vote.candidateId,
          amount: Number(vote.amount / 1000000000000000000n),
          index: vote.index,
        })),
      }));
  }, [voteLogs, votedCandidateIds]);

  let { data: nicknames } = useSuspenseQuery(
    batchNicknamesQuery(topSpenders.map(spender => spender.address)),
  );

  let renderVoteAvatars = (
    votes: { candidateId: bigint | null; amount: number }[],
  ) => {
    let consolidatedVotes = votes.reduce(
      (acc, vote) => {
        let key = vote.candidateId?.toString() ?? 'unknown';
        if (!acc[key]) {
          acc[key] = {
            candidateId: vote.candidateId,
            amounts: [vote.amount],
            totalAmount: vote.amount,
          };
        } else {
          acc[key].amounts.push(vote.amount);
          acc[key].totalAmount += vote.amount;
        }
        return acc;
      },
      {} as Record<
        string,
        { candidateId: bigint | null; amounts: number[]; totalAmount: number }
      >,
    );

    return (
      <AvatarGroup>
        {Object.values(consolidatedVotes).map((vote, index) => {
          let choice =
            vote.candidateId !== null
              ? pollDetail.choices.find(
                  (_choice, index) => BigInt(index) === vote.candidateId,
                )
              : null;

          let amountsDisplay = vote.amounts.sort((a, b) => b - a).join(' + ');
          let tooltipContent = `${choice ? choice.title : 'Unknown'}: ${
            vote.amounts.length > 1
              ? `${amountsDisplay} = ${vote.totalAmount} COMO`
              : `${vote.totalAmount} COMO`
          }`;

          return (
            <NextUITooltip
              key={vote.candidateId?.toString() ?? `unknown-${index}`}
              content={tooltipContent}
            >
              <Avatar
                isBordered
                className='w-8 h-8 data-[hover=true]:translate-x-0'
                src={choice?.txImageUrl}
                fallback={!choice && <span className='text-2xl'>?</span>}
              />
            </NextUITooltip>
          );
        })}
      </AvatarGroup>
    );
  };

  let { data: timestamps } = useQuery({
    ...batchBlockTimestampsQuery(
      Array.from(
        new Set(
          topSpenders
            .map(spender => spender.votes)
            .flat()
            .map(vote => voteLogs[vote.index].blockNumber),
        ),
      ),
    ),
    enabled: showTimestamps,
  });
  timestamps ??= {};

  return (
    <div className='mb-4'>
      <div className='flex flex-col md:flex-row justify-between gap-2 mb-4'>
        <h4 className='text-lg font-semibold'>Top 50 COMO Spenders</h4>
        <Switch
          size='sm'
          color='secondary'
          isSelected={showTimestamps}
          onValueChange={setShowTimestamps}
        >
          Show Timestamps
        </Switch>
      </div>
      {isLoading ? (
        <div className='w-full h-64 flex items-center justify-center'>
          <Spinner />
        </div>
      ) : (
        topSpenders.map((spender, index) => (
          <div
            key={index}
            className='flex flex-col p-2 border-b border-divider last:border-b-0'
          >
            <div className='flex items-center justify-between min-w-0'>
              <div className='flex items-center min-w-0 flex-1 mr-4'>
                <span className='font-bold mr-2 w-8 flex-shrink-0 text-right tabular-nums'>
                  {spender.rank}
                </span>
                <img
                  src='https://static.cosmo.fans/uploads/images/img_profile_gallag@3x.png'
                  alt=''
                  className='w-8 h-8 rounded-full mr-2 flex-shrink-0'
                />
                <Link
                  to={
                    nicknames[spender.address]
                      ? `/@${nicknames[spender.address]}`
                      : `/${spender.address}`
                  }
                  className='truncate'
                >
                  {nicknames[spender.address] || `${spender.address}`}
                </Link>
              </div>
              <div className='flex items-center gap-4 flex-shrink-0'>
                <div className='flex-shrink-0'>
                  {renderVoteAvatars(spender.votes)}
                </div>
                <span className='min-w-24 flex-shrink-0 text-right'>
                  {spender.totalAmount} COMO
                </span>
              </div>
            </div>
            {showTimestamps && (
              <div className='text-sm text-default-500 mt-2'>
                {!timestamps[voteLogs[spender.votes[0].index].blockNumber] ? (
                  <div className='flex justify-center'>
                    <Spinner size='sm' />
                  </div>
                ) : (
                  spender.votes.map(vote => {
                    let voteLog = voteLogs[vote.index];
                    let timestamp = timestamps[voteLog.blockNumber];
                    return (
                      timestamp && (
                        <div key={vote.index} className='flex text-right'>
                          <div className='flex-1'>
                            {new Date(timestamp * 1000).toLocaleString('en-US')}
                          </div>
                          <div className='w-24'>{vote.amount} COMO</div>
                        </div>
                      )
                    );
                  })
                )}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
}

function GravityPollGraph({
  voteLogs,
  poll,
  isLoading,
}: {
  voteLogs: VoteLog[];
  poll: Poll;
  isLoading: boolean;
}) {
  let chartData = useMemo(() => {
    let comosBurntByBlockGroup: { [key: number]: number } = {};

    for (let voteLog of voteLogs) {
      let blockGroup = Math.floor(voteLog.blockNumber / 450);
      let comoAmount = Number(voteLog.comoAmount / 1000000000000000000n);
      comosBurntByBlockGroup[blockGroup] =
        (comosBurntByBlockGroup[blockGroup] || 0) + comoAmount;
    }

    let result = Object.entries(comosBurntByBlockGroup).map(
      ([blockGroup, comosBurnt]) => ({
        blockGroup: parseInt(blockGroup),
        comosBurnt,
      }),
    );

    if (!poll.finalized) {
      let endDate = new Date(poll.endDate);
      let additionalExpectedBlockGroups =
        (endDate.getTime() - new Date().getTime()) / (15 * 60 * 1000);
      let maxBlockGroup = Math.max(...result.map(item => item.blockGroup), 0);
      for (let i = 0; i < additionalExpectedBlockGroups; i++) {
        result.push({
          blockGroup: maxBlockGroup + i,
          comosBurnt: 0,
        });
      }
    }

    return result.sort((a, b) => a.blockGroup - b.blockGroup);
  }, [voteLogs, poll]);

  if (isLoading) {
    return (
      <div className='w-full h-64 flex items-center justify-center bg-content1 rounded-lg'>
        <Spinner />
      </div>
    );
  }

  return (
    <div className='w-full h-64 bg-content1 p-4 rounded-lg'>
      <div className='mb-2 flex justify-between items-center'>
        <h3 className='text-sm font-medium'>Vote Activity</h3>
        <div className='text-xs text-default-500'>
          Total COMO:{' '}
          {chartData.reduce((sum, item) => sum + item.comosBurnt, 0)}
        </div>
      </div>
      <ResponsiveContainer width='100%' height='90%'>
        <BarChart
          data={chartData}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        >
          <Tooltip
            cursor={{ className: 'fill-secondary opacity-20' }}
            content={({ active, payload }) => {
              if (!active || !payload?.length) return null;
              return (
                <div className='bg-white shadow-lg rounded-lg px-3 py-2 border border-default-200'>
                  <div className='text-sm font-medium text-default-700'>
                    {payload[0].value} COMO
                  </div>
                </div>
              );
            }}
          />
          <Bar
            dataKey='comosBurnt'
            className='fill-secondary-300'
            radius={[4, 4, 0, 0]}
            maxBarSize={50}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

function RevealTab({
  totalVotes,
  revealedVotes,
  pollDetail,
}: {
  totalVotes: bigint;
  revealedVotes: bigint[];
  pollDetail: PollDetail;
}) {
  let percentage =
    totalVotes === 0n
      ? 0
      : Number(
          (revealedVotes.reduce((a, b) => a + b, 0n) / 1000000000000000000n) *
            100n,
        ) / Number(totalVotes / 1000000000000000000n);
  return (
    <div className='flex flex-col flex-wrap gap-2'>
      <div className='w-full h-1 rounded-full'>
        <motion.div
          initial={false}
          animate={{ width: `${percentage}%` }}
          className='h-full bg-blue-500 bg-opacity-20 rounded-full'
        />
      </div>
      {pollDetail.choices
        .map((choice, index) => ({
          choice,
          voteCount: revealedVotes[index],
          index,
        }))
        .sort((a, b) =>
          Number(
            b.voteCount / 1000000000000000000n -
              a.voteCount / 1000000000000000000n,
          ),
        )
        .map(({ choice, voteCount }) => {
          let percentage =
            totalVotes > 0n
              ? Number((voteCount / 1000000000000000000n) * 100n) /
                Number(totalVotes / 1000000000000000000n)
              : 0;

          return (
            <motion.div
              layout
              key={choice.id}
              className='inline-flex m-0 bg-content1 hover:bg-content2 items-center justify-between max-w-full border-2 border-transparent rounded-lg gap-4 p-4 relative'
            >
              <motion.div
                animate={{ width: `${percentage}%` }}
                className='absolute top-0 left-0 m-[-2px] h-[calc(100%_+_4px)] rounded-s-lg bg-blue-500 bg-opacity-20'
              />
              <div className='flex flex-1 items-center ml-2 z-10 min-w-0'>
                <img
                  src={choice.txImageUrl}
                  alt={choice.title}
                  className='w-12 h-12 object-cover mr-2 flex-shrink-0'
                />
                <div className='flex flex-col min-w-0'>
                  <span className='truncate'>{choice.title}</span>
                  <span className='text-xs text-gray-500 truncate'>
                    {choice.description}
                  </span>
                </div>
              </div>
              <span className='text-sm whitespace-nowrap flex-shrink-0'>
                {Number(voteCount / 1000000000000000000n)} COMO (
                {percentage.toFixed(1)}%)
              </span>
            </motion.div>
          );
        })}
    </div>
  );
}

function GravityPoll({
  artist,
  poll,
  pollDetail,
}: {
  artist: 'tripleS' | 'artms';
  poll: Poll;
  pollDetail: PollDetail;
}) {
  let [activeTab, setActiveTab] = useState('vote');
  let [isLoading, setIsLoading] = useState(true);

  let pollIdOnChain = poll.pollIdOnChain || poll.id;
  let [voteLogs, setVoteLogs] = useState<VoteLog[]>([]);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      setVoteLogs([]);
      setIsLoading(true);
      let contract = contracts[artist];
      let pollCreatedEventLogs = await contract.queryFilter('PollCreated');
      let pollCreatedEvent = pollCreatedEventLogs.find(
        log => (log as EventLog).args[0] == pollIdOnChain,
      );
      if (!pollCreatedEvent) {
        console.error('PollCreated event not found');
        return;
      }
      let startBlockNumber = pollCreatedEvent.blockNumber;
      let finalizedFilter = contract.filters.Finalized(pollIdOnChain);
      let finalizedEventLogs: EventLog[] = [];
      let endBlockNumber: number;
      while (!isCancelled) {
        finalizedEventLogs = (await contract.queryFilter(
          finalizedFilter,
        )) as EventLog[];
        endBlockNumber = finalizedEventLogs.length
          ? finalizedEventLogs[0].blockNumber
          : await provider.getBlockNumber();

        let votedFilter = contract.filters.Voted(pollIdOnChain);
        let votedEventLogsPromises: Promise<EventLog[]>[] = [];
        for (
          let blockNumber = startBlockNumber;
          blockNumber < endBlockNumber;
          blockNumber += 2000
        ) {
          votedEventLogsPromises.push(
            contract.queryFilter(
              votedFilter,
              blockNumber,
              Math.min(endBlockNumber - 1, blockNumber + 1999),
            ) as Promise<EventLog[]>,
          );
        }

        let votedEventLogs = (await Promise.all(votedEventLogsPromises)).flat();
        let newVoteLogs = votedEventLogs.map(
          ({
            args: [pollId, voteIndex, voter, comoAmount, hash],
            blockNumber,
          }) => ({
            pollId,
            voteIndex,
            voter,
            comoAmount,
            hash,
            blockNumber,
          }),
        );
        if (!isCancelled) {
          setIsLoading(false);
          setVoteLogs(voteLogs => [...voteLogs, ...newVoteLogs]);
        }
        startBlockNumber = endBlockNumber;
        if (finalizedEventLogs.length) {
          break;
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    })();
    return () => void (isCancelled = true);
  }, [artist, pollIdOnChain]);

  let [votedCandidateIds, setVotedCandidateIds] = useState<bigint[]>([]);

  useEffect(() => {
    let isCancelled = false;
    (async () => {
      setVoteLogs([]);
      setIsLoading(true);
      let contract = contracts[artist];
      let pollCreatedEventLogs = await contract.queryFilter('PollCreated');
      let pollCreatedEvent = pollCreatedEventLogs.find(
        log => (log as EventLog).args[0] == pollIdOnChain,
      );
      if (!pollCreatedEvent) {
        console.error('PollCreated event not found');
        return;
      }
      let startBlockNumber = pollCreatedEvent.blockNumber;
      let finalizedFilter = contract.filters.Finalized(pollIdOnChain);
      let finalizedEventLogs: EventLog[] = [];
      let endBlockNumber: number;
      while (!isCancelled) {
        finalizedEventLogs = (await contract.queryFilter(
          finalizedFilter,
        )) as EventLog[];
        endBlockNumber = finalizedEventLogs.length
          ? finalizedEventLogs[0].blockNumber
          : await provider.getBlockNumber();

        let revealedFilter = contract.filters.Revealed(pollIdOnChain);
        let revealedEventLogsPromises: Promise<EventLog[]>[] = [];
        for (
          let blockNumber = startBlockNumber;
          blockNumber < endBlockNumber;
          blockNumber += 2000
        ) {
          revealedEventLogsPromises.push(
            contract.queryFilter(
              revealedFilter,
              blockNumber,
              Math.min(endBlockNumber - 1, blockNumber + 1999),
            ) as Promise<EventLog[]>,
          );
        }

        let revealedEventLogs = (
          await Promise.all(revealedEventLogsPromises)
        ).flat();
        let revealedTransactions = await Promise.all(
          revealedEventLogs.map(eventLog => eventLog.getTransaction()),
        );
        let newVotedCandidateIds: bigint[] = [];
        if (revealedTransactions.length) {
          let baseOffset = Number(
            contract.interface.parseTransaction(revealedTransactions[0])!
              .args[2],
          );
          for (let revealedTransaction of revealedTransactions) {
            let tx = contract.interface.parseTransaction(revealedTransaction)!;
            if (
              newVotedCandidateIds.length ===
              Number(tx.args[2]) - baseOffset
            ) {
              newVotedCandidateIds.push(
                ...(tx.args[1] as [bigint, bigint][]).map(
                  ([votedCandidateId, _]) => votedCandidateId,
                ),
              );
            }
          }
        }
        if (!isCancelled) {
          setIsLoading(false);
          setVotedCandidateIds(votedCandidateIds => [
            ...votedCandidateIds,
            ...newVotedCandidateIds,
          ]);
        }
        startBlockNumber = endBlockNumber;
        if (finalizedEventLogs.length) {
          break;
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    })();
    return () => void (isCancelled = true);
  }, [artist, pollIdOnChain]);

  let totalVotes = voteLogs
    .map(voteLog => voteLog.comoAmount)
    .reduce((a, b) => a + b, 0n);

  let [revealedVotes, setRevealedVotes] = useState<bigint[]>(
    pollDetail.choices.map(() => 0n),
  );
  let [isRevealing, setIsRevealing] = useState(false);

  let checkAndRevealVotes = useCallback(async () => {
    if (new Date() < new Date(poll.endDate)) {
      return;
    }
    if (!isRevealing) {
      setIsRevealing(true);
    }

    try {
      let contract = contracts[artist];
      let votes = await contract.votesPerCandidates(
        poll.pollIdOnChain || poll.id,
      );
      if (
        revealedVotes.length !== votes.length ||
        revealedVotes.some((vote, index) => vote !== votes[index])
      ) {
        setRevealedVotes(votes);
      }
    } catch (error) {
      console.error('Error fetching votes:', error);
    }
  }, [artist, poll, isRevealing]);

  useEffect(() => {
    let interval = setInterval(checkAndRevealVotes, 1000);
    return () => clearInterval(interval);
  }, [checkAndRevealVotes]);

  return (
    <>
      <GravityPollGraph voteLogs={voteLogs} poll={poll} isLoading={isLoading} />
      <Tabs
        className='mt-4 mb-2'
        classNames={{ panel: 'p-0' }}
        selectedKey={activeTab}
        onSelectionChange={key => setActiveTab(key as string)}
      >
        <Tab key='vote' title='Vote'>
          <VoteTab artist={artist} pollDetail={pollDetail} />
        </Tab>
        <Tab key='leaderboard' title='Leaderboard'>
          <LeaderboardTab
            voteLogs={voteLogs}
            votedCandidateIds={votedCandidateIds}
            pollDetail={pollDetail}
            isLoading={isLoading}
          />
        </Tab>
        <Tab key='reveal' title='Results'>
          {isRevealing ? (
            <RevealTab
              totalVotes={totalVotes}
              revealedVotes={revealedVotes}
              pollDetail={pollDetail}
            />
          ) : (
            <div className='text-center py-4'>
              Results will be revealed after the poll ends.
            </div>
          )}
        </Tab>
      </Tabs>
    </>
  );
}

function renderBodyItem(item: BodyItem, key: React.Key) {
  switch (item.type) {
    case 'spacing':
      return (
        <div
          key={key}
          className='h-0'
          style={{ paddingBottom: `${(item.height / 375) * 100}%` }}
        />
      );
    case 'heading':
      return (
        <h2
          key={key}
          className='text-lg font-semibold'
          style={{ textAlign: item.align }}
        >
          {item.text}
        </h2>
      );
    case 'text':
      return (
        <p key={key} className='mb-4 text-sm' style={{ textAlign: item.align }}>
          {(item.text ?? '').split('\n').map((line, index) => (
            <Fragment key={index}>
              {index !== 0 && <br />}
              {line}
            </Fragment>
          ))}
        </p>
      );
    case 'image':
      return (
        <div
          key={key}
          className='relative h-0'
          style={{
            paddingBottom: `${(item.height / 375) * 100}%`,
          }}
        >
          <img src={item.imageUrl} className='absolute offset-0 bg-black' />
        </div>
      );
    case 'video':
      return (
        <div key={key}>
          <video
            className='w-full'
            poster={item.thumbnailImageUrl}
            // FIXME: HLS doesn't work at all
            src={item.videoUrl}
            playsInline
          />
        </div>
      );
    default:
      return null;
  }
}

export async function loader({ params }: LoaderFunctionArgs) {
  let { artist, gravityId } = params as {
    artist: 'tripleS' | 'artms' | undefined;
    gravityId: string;
  };
  artist ??= 'tripleS';
  let parsedId = parseInt(gravityId);
  let gravity = await queryClient.fetchQuery(gravityQuery(artist, parsedId));
  await Promise.all(
    gravity.polls.map(poll =>
      queryClient.prefetchQuery(pollDetailQuery(artist, parsedId, poll.id)),
    ),
  );

  return null;
}

export default function GravityDetail() {
  let { artist, gravityId } = useParams() as {
    artist: 'tripleS' | 'artms' | undefined;
    gravityId: string;
  };
  artist ??= 'tripleS';
  let parsedId = parseInt(gravityId);

  let { data: gravity } = useSuspenseQuery(gravityQuery(artist, parsedId));
  let bodyContent = gravity.body;
  let pollDetailQueryResults = useSuspenseQueries({
    queries: gravity.polls.map(poll =>
      pollDetailQuery(artist, parsedId, poll.id),
    ),
  });
  let pollDetails = Object.fromEntries(
    gravity.polls.map((poll, index) => [
      poll.id,
      pollDetailQueryResults[index].data,
    ]),
  );

  return (
    <div className='max-w-5xl mx-auto px-6 py-2'>
      <div className='flex flex-col sm:flex-row gap-4'>
        <div className='sm:w-2/3'>
          <div className='mb-4'>
            <h1 className='text-3xl font-bold mb-2'>{gravity.title}</h1>
            <span
              className={`px-2 py-1 rounded text-tiny uppercase font-bold ${
                gravity.type === 'event-gravity'
                  ? 'bg-green-100 text-green-800'
                  : gravity.type === 'grand-gravity'
                    ? 'bg-purple-100 text-purple-800'
                    : 'bg-gray-100 text-gray-800'
              }`}
            >
              {gravity.type}
            </span>
            <div className='text-sm text-gray-600 mt-2'>
              {gravity.entireStartDate && gravity.entireEndDate && (
                <>
                  {new Date(gravity.entireStartDate).toLocaleDateString(
                    'en-US',
                  )}
                  {' - '}
                  {new Date(gravity.entireEndDate).toLocaleDateString('en-US')}
                </>
              )}
            </div>
            <span className='text-tiny text-gray-600 mt-1'>
              NOTE: This is a work-in-progress page. Sorry for the
              inconvenience.
            </span>
          </div>
          <Tabs
            className={gravity.polls.length > 1 ? '' : 'hidden'}
            classNames={{ panel: 'p-0' }}
          >
            {gravity.polls.map(poll => (
              <Tab key={poll.id} title={poll.title}>
                <GravityPoll
                  artist={artist}
                  poll={poll}
                  pollDetail={pollDetails[poll.id]}
                />
              </Tab>
            ))}
          </Tabs>
        </div>

        <div className='sm:w-1/3'>
          <img src={gravity.bannerImageUrl} alt='' />
          {bodyContent.map((item, index) => renderBodyItem(item, index))}
        </div>
      </div>

      {gravity.contractOutlink && (
        <div className='mt-8'>
          <a
            href={gravity.contractOutlink}
            target='_blank'
            rel='noopener noreferrer'
            className='text-blue-500 hover:underline'
          >
            View Contract on Polygonscan
          </a>
        </div>
      )}
    </div>
  );
}
