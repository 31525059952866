import { useState, useEffect, useLayoutEffect, useRef, Suspense } from 'react';
import {
  Avatar,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Button,
  Link as NextUILink,
  LinkProps as NextUILinkProps,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  useDisclosure,
  User,
  Select,
  SelectItem,
  Spinner,
} from '@nextui-org/react';

import {
  Outlet,
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { TRANSITION_DEFAULTS } from '@nextui-org/framer-utils';
import { captureException } from '@sentry/react';

import {
  SendIcon,
  UserIcon,
  UserPlusIcon,
  MoonIcon,
  SunIcon,
  SettingsIcon,
  LogoutIcon,
  DesktopIcon,
  SearchIcon,
} from '../icons';

import { CosmoClient, cosmoClient, Profile } from '../cosmo';
import { useAccounts, useProfile } from '../credentials';

import { getRamperWalletSecrets } from '../ramper';
import { PolarisClient } from '../polaris';
import { CosmoNicknameAutocomplete } from '../components/cosmo';

export function Link(props: NextUILinkProps & ReactRouterLinkProps) {
  return <NextUILink as={ReactRouterLink} {...props} />;
}

function LoginModal({ onClose }: { onClose: () => void }) {
  let { addAccount } = useAccounts();

  let [screen, setScreen] = useState(0);
  let [email, setEmail] = useState('');
  let [transactionId, setTransactionId] = useState('');
  let [pendingToken, setPendingToken] = useState('');
  let [isLoading, setIsLoading] = useState(false);
  let [error, setError] = useState('');

  async function onNextButtonPress() {
    try {
      setIsLoading(true);
      setError('');
      if (screen === 0) {
        let { transactionId, pendingToken } =
          await cosmoClient.requestSignIn(email);
        setTransactionId(transactionId);
        setPendingToken(pendingToken);
        setScreen(1);
      } else if (screen === 1) {
        let { customToken, socialLoginUserId, ...credentials } =
          await cosmoClient.signIn(email, transactionId, pendingToken);
        let ramperWalletSecrets = await getRamperWalletSecrets(
          socialLoginUserId,
          customToken,
        );
        let profile: Profile;
        try {
          profile = await new PolarisClient().signIn(socialLoginUserId);
        } catch (error) {
          console.error(error);
          let temporaryCosmoClient = new CosmoClient();
          temporaryCosmoClient.credentials = credentials;
          profile = await temporaryCosmoClient.getMyProfile();
        }
        addAccount({
          credentials,
          profile,
          ramperWalletSecrets,
          socialLoginUserId,
        });
        setScreen(2);
      } else if (screen === 2) {
        onClose();
      }
    } catch (error) {
      console.error(error);
      captureException(error);
      if (error instanceof Error) {
        setError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onNextButtonPress();
      }}
    >
      <ModalHeader>Login to COSMO</ModalHeader>
      <ModalBody>
        {screen === 0 && (
          <>
            <p>Please enter your email address to request a sign in link.</p>
            <Input
              type='email'
              label='Email'
              placeholder='Enter your email'
              value={email}
              onValueChange={setEmail}
              classNames={{ input: 'text-base placeholder:text-small' }}
            />
          </>
        )}
        {screen === 1 && (
          <>
            <p>
              We have sent you an email with a link to confirm your sign in.
              Please check your inbox and click on the link{' '}
              <span className='text-blue-500'>
                confirm from a different device
              </span>
              .
            </p>
            <img
              src={
                new URL('../resources/cosmo-login-mail.png', import.meta.url)
                  .href
              }
            />
          </>
        )}
        {screen === 2 && <p>You have successfully signed in.</p>}
        {error && <p className='text-red-500'>{error}</p>}
      </ModalBody>
      <ModalFooter>
        <Button color='danger' variant='light' onPress={onClose}>
          Close
        </Button>
        <Button color='primary' type='submit' isLoading={isLoading}>
          {screen === 0 && 'Send Email'}
          {screen === 1 && 'Check Confirmation'}
          {screen === 2 && 'Dismiss'}
        </Button>
      </ModalFooter>
    </form>
  );
}

type ThemeMode = 'light' | 'dark' | 'auto';

// FIXME: This should be a context provider, so that the effects can run on mount
function useThemeMode() {
  let [themeMode, setThemeMode_] = useState<ThemeMode>('auto');
  useLayoutEffect(() => {
    document.body.classList.add(
      'min-h-screen',
      'text-foreground',
      'bg-background',
    );
    let themeMode = localStorage.getItem('theme') ?? 'auto';
    if (themeMode !== 'light' && themeMode !== 'dark' && themeMode !== 'auto') {
      localStorage.removeItem('theme');
      themeMode = 'auto';
    }
    setThemeMode_(themeMode as ThemeMode);
  }, []);
  function setThemeMode(themeMode: ThemeMode) {
    if (themeMode === 'light') {
      localStorage.setItem('theme', 'light');
    } else if (themeMode === 'dark') {
      localStorage.setItem('theme', 'dark');
    } else {
      localStorage.removeItem('theme');
    }
    setThemeMode_(themeMode);
  }
  function setTheme(theme: 'light' | 'dark') {
    if (theme === 'light') {
      document.body.classList.remove('dark');
    } else {
      document.body.classList.add('dark');
    }
  }
  useLayoutEffect(() => {
    if (themeMode !== 'auto') {
      setTheme(themeMode);
      return;
    }
    let query = window.matchMedia('(prefers-color-scheme: dark)');
    setTheme(query.matches ? 'dark' : 'light');
    function onChange() {
      setTheme(query.matches ? 'dark' : 'light');
    }
    query.addEventListener('change', onChange);
    return () => query.removeEventListener('change', onChange);
  }, [themeMode]);
  return { themeMode, setThemeMode };
}

function SettingsModal({
  onClose,
  themeMode,
  setThemeMode,
}: {
  onClose: () => void;
  themeMode: ThemeMode;
  setThemeMode: (mode: ThemeMode) => void;
}) {
  return (
    <>
      <ModalHeader>Settings</ModalHeader>
      <ModalBody>
        <h3 className='text-lg font-semibold'>Theme</h3>
        <Select
          label='Theme'
          placeholder='Select a theme'
          selectedKeys={[themeMode]}
          onSelectionChange={keys =>
            keys.currentKey && setThemeMode(keys.currentKey as ThemeMode)
          }
          startContent={
            themeMode === 'light' ? (
              <SunIcon />
            ) : themeMode === 'dark' ? (
              <MoonIcon />
            ) : (
              <DesktopIcon />
            )
          }
        >
          <SelectItem
            key='light'
            value='light'
            startContent={<SunIcon className='w-4 h-4' />}
          >
            Light
          </SelectItem>
          <SelectItem
            key='dark'
            value='dark'
            startContent={<MoonIcon className='w-4 h-4' />}
          >
            Dark
          </SelectItem>
          <SelectItem
            key='auto'
            value='auto'
            startContent={<DesktopIcon className='w-4 h-4' />}
          >
            System
          </SelectItem>
        </Select>
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onPress={onClose}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
}

export default function Root() {
  let {
    isLoading: isAccountsLoading,
    accounts,
    removeAccount,
    setCurrentAccount,
  } = useAccounts();
  let profile = useProfile();
  let profileImageUrl = profile?.profile.length
    ? profile.profile[0].image.original
    : profile?.profileImageUrl ||
      'https://static.cosmo.fans/uploads/images/img_profile_gallag@3x.png';
  let { isOpen, onOpen, onOpenChange } = useDisclosure();
  let [isMenuOpen, setIsMenuOpen] = useState(false);
  let location = useLocation();
  let navigate = useNavigate();
  let [nickname, setNickname] = useState('');
  useEffect(() => setIsMenuOpen(false), [location]);
  let { themeMode, setThemeMode } = useThemeMode();
  let {
    isOpen: isSettingsOpen,
    onOpen: onSettingsOpen,
    onOpenChange: onSettingsOpenChange,
  } = useDisclosure();
  let [isMobile, setIsMobile] = useState(false);

  let searchRef = useRef<HTMLDivElement>(null);
  let [isSearchExpanded, setIsSearchExpanded] = useState(false);

  useLayoutEffect(() => {
    let query = window.matchMedia('(min-width: 640px)');
    function updateIsMobile() {
      setIsMobile(!query.matches);
    }
    updateIsMobile();
    query.addEventListener('change', updateIsMobile);
    return () => {
      query.removeEventListener('change', updateIsMobile);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        searchRef.current &&
        !searchRef.current.contains(event.target as Node)
      ) {
        setIsSearchExpanded(false);
      }
    }

    if (isSearchExpanded) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSearchExpanded]);

  return (
    <>
      <Navbar isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen}>
        <NavbarContent className='data-[justify=start]:grow-0'>
          <NavbarMenuToggle
            aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
            className='sm:hidden'
          />
          <NavbarBrand className='grow-0 sm:flex'>
            <AnimatePresence initial={false}>
              {!(isSearchExpanded && isMobile) && (
                <motion.div
                  initial={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: 'auto' }}
                  exit={{ opacity: 0, width: 0 }}
                  transition={TRANSITION_DEFAULTS}
                  className='overflow-hidden'
                >
                  <div>
                    <p className='font-bold text-inherit'>
                      <Link color='foreground' to='/'>
                        Polaris
                      </Link>
                    </p>
                    <p className='text-tiny leading-[.5rem] text-foreground-500'>
                      by <a href='https://twitter.com/3somsoc'>@3somsoc</a>
                    </p>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </NavbarBrand>
          <NavbarItem
            className='hidden sm:block'
            isActive={location.pathname === '/'}
          >
            <Link color='foreground' to='/'>
              Objekts
            </Link>
          </NavbarItem>
          <NavbarItem
            className='hidden sm:block'
            isActive={location.pathname === '/gravity'}
          >
            <Link to='/gravity'>Gravity</Link>
          </NavbarItem>
        </NavbarContent>
        <NavbarMenu>
          <NavbarMenuItem>
            <Link color='foreground' to='/'>
              Objekts
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <Link color='foreground' to='/gravity'>
              Gravity
            </Link>
          </NavbarMenuItem>
          <NavbarMenuItem>
            <form
              className='flex gap-2'
              onSubmit={e => {
                e.preventDefault();
                navigate(`/@${nickname}`);
              }}
            >
              <CosmoNicknameAutocomplete
                nickname={nickname}
                setNickname={setNickname}
                placeholder='Search user by COSMO ID'
              />
              <Button type='submit' isIconOnly color='primary' variant='flat'>
                <SendIcon className='w-6 h-6' />
              </Button>
            </form>
          </NavbarMenuItem>
        </NavbarMenu>
        <NavbarContent justify='end' className='gap-0'>
          <NavbarItem>
            <div className='flex justify-end w-full'>
              <div
                className='relative flex items-center w-full gap-2'
                ref={searchRef}
              >
                <AnimatePresence initial={false}>
                  {isSearchExpanded && (
                    <motion.div
                      initial={{ opacity: 0, width: 0 }}
                      animate={{ opacity: 1, width: 'auto' }}
                      exit={{ opacity: 0, width: 0 }}
                      transition={TRANSITION_DEFAULTS}
                    >
                      <form
                        onSubmit={e => {
                          e.preventDefault();
                          if (nickname) {
                            navigate(`/@${nickname}`);
                            setIsSearchExpanded(false);
                          }
                        }}
                      >
                        <CosmoNicknameAutocomplete
                          nickname={nickname}
                          setNickname={setNickname}
                          placeholder='Search user by COSMO ID'
                          className='w-full'
                        />
                      </form>
                    </motion.div>
                  )}
                </AnimatePresence>
                {!isSearchExpanded ? (
                  <Button
                    onClick={() => setIsSearchExpanded(true)}
                    isIconOnly
                    variant='light'
                  >
                    <SearchIcon className='w-6 h-6 text-foreground-500' />
                  </Button>
                ) : (
                  <Button
                    isIconOnly
                    color='primary'
                    variant='flat'
                    onClick={() => {
                      if (nickname) {
                        navigate(`/@${nickname}`);
                        setIsSearchExpanded(false);
                      }
                    }}
                  >
                    <SendIcon className='w-6 h-6' />
                  </Button>
                )}
              </div>
            </div>
          </NavbarItem>
          <AnimatePresence initial={false}>
            {!(isSearchExpanded && isMobile) && (
              <motion.div
                initial={{ opacity: 0, width: 0 }}
                animate={{ opacity: 1, width: 'auto' }}
                exit={{ opacity: 0, width: 0 }}
                transition={TRANSITION_DEFAULTS}
              >
                <NavbarItem className='ps-4'>
                  {profile ? (
                    <Dropdown
                      shouldBlockScroll={false}
                      triggerScaleOnOpen={false}
                    >
                      <DropdownTrigger>
                        <Button
                          radius='full'
                          variant='light'
                          size='lg'
                          className={!isMobile ? 'ps-1 pe-4' : 'px-1 min-w-0'}
                        >
                          {!isMobile ? (
                            <User
                              name={'@' + profile.nickname}
                              description={profile.address}
                              classNames={{ description: 'max-w-32 text-ellipsis overflow-hidden' }}
                              avatarProps={{ src: profileImageUrl }}
                            />
                          ) : (
                            <Avatar isBordered src={profileImageUrl} />
                          )}
                        </Button>
                      </DropdownTrigger>
                      <DropdownMenu
                        onAction={key => {
                          if (key === 'login') {
                            onOpen();
                          } else if (key === 'logout') {
                            removeAccount(profile!.id);
                          } else if (key === 'settings') {
                            onSettingsOpen();
                          } else if (key === 'home') {
                            navigate('/');
                          } else if (
                            typeof key === 'string' &&
                            key.startsWith('profile-')
                          ) {
                            let profileId = parseInt(
                              key.slice('profile-'.length),
                            );
                            setCurrentAccount(profileId);
                          }
                        }}
                        aria-label='Account Actions'
                      >
                        {[
                          <DropdownItem className='ps-1.5' key='home'>
                            <User
                              name={'@' + profile.nickname}
                              description={profile.email}
                              avatarProps={{ src: profileImageUrl }}
                            />
                          </DropdownItem>,
                          ...accounts.slice(1).map(account => (
                            <DropdownItem
                              key={`profile-${account.profile.id}`}
                              startContent={<UserIcon className='w-4 h-4' />}
                            >
                              @{account.profile.nickname}
                            </DropdownItem>
                          )),
                          <DropdownItem
                            key='login'
                            startContent={
                              <UserPlusIcon className='w-4 h-4 translate-x-px' />
                            }
                          >
                            Add account
                          </DropdownItem>,
                          <DropdownItem
                            key='settings'
                            startContent={<SettingsIcon className='w-4 h-4' />}
                          >
                            Settings
                          </DropdownItem>,
                          <DropdownItem
                            key='logout'
                            className='text-danger'
                            color='danger'
                            startContent={
                              <LogoutIcon className='w-4 h-4 rotate-180 translate-x-px' />
                            }
                          >
                            Logout
                          </DropdownItem>,
                        ]}
                      </DropdownMenu>
                    </Dropdown>
                  ) : (
                    <Button
                      color='primary'
                      onClick={onOpen}
                      isLoading={isAccountsLoading}
                    >
                      {isAccountsLoading ? 'Logging in…' : 'Login'}
                    </Button>
                  )}
                </NavbarItem>
              </motion.div>
            )}
          </AnimatePresence>
        </NavbarContent>
      </Navbar>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} placement='top-center'>
        <ModalContent>
          {onClose => <LoginModal onClose={onClose} />}
        </ModalContent>
      </Modal>
      <Modal isOpen={isSettingsOpen} onOpenChange={onSettingsOpenChange}>
        <ModalContent>
          {onClose => (
            <SettingsModal
              onClose={onClose}
              themeMode={themeMode}
              setThemeMode={setThemeMode}
            />
          )}
        </ModalContent>
      </Modal>
      <Suspense
        fallback={
          <div className='w-full h-64 flex items-center justify-center'>
            <Spinner />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </>
  );
}
